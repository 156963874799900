<template>
  <div class="permis-left">
    <div class="title">
      设备类型
      <span class="now-type">选中类型：{{nowType}}</span>
    </div>
    <div class="left-body">
      <el-tree
          :props="props"
          :load="loadNode"
          lazy
          @node-click="handleNodeClick">
      </el-tree>
    </div>
  </div>

</template>

<script>
import common from "../../../../api/modules/common";

export default {
  name: "SysLeftTree",
  data() {
    return {
      activeType: '',
      props: {
        isLeaf: 'leaf'
      },
      nowType: ''
    }
  },
  mounted() {
  },
  methods: {
    changeType(item) {
      this.activeType = item.equipTypeId
      this.$emit('change', item.equipTypeId)
    },
    loadNode(node, resolve) {
      const { level,data } = node;
      let id =  data && data.value? data.value :0
      common.getEquipmentList({equipTypeId:id}).then(res =>{
        if(res.code == 200 && res.data){
          let data = res.data.map(item =>{
            return {
              value: item.equipTypeId,
              label: item.equipTypeName,
              isSpecial: item.isSpecial,  // 1时为特种设备
              leaf: level >=1
            }
          })
          resolve(data);
        }
      })
    },
    handleNodeClick(val){
      if(val.leaf){
        this.nowType = val.label
        this.$emit('change', val.value)
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../../assets/css/selfcheck.less";
.left-body{
  overflow: auto;
  height: calc(100% - 65px)
}
.now-type{
  font-size: 12px;
}

</style>