<template>
  <div class="pageMain permis-main">
    <sys-left-tree @change="treeChange"></sys-left-tree>
    <div class="permis-right">
      <div class="right-title">
        <el-row>
          <el-col :span="12">
            <div class="title">
              <i class="el-icon-s-operation"></i>设置自检项目&nbsp;
            </div>
          </el-col>
          <el-col :span="12" class="right">
            <el-button size="small" type="primary" plain @click="savePermiss">新增</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right-body">
        <el-table
            :data="tableData"
            border
            header-row-class-name="custom-header-row"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <el-table-column
              prop="itemName"
              label="自检项目"
              width="240">
            <template slot-scope="scope">
              <el-input v-model="scope.row.itemName" placeholder="请输入自检项目"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="itemDesc"
              label="自检要求">
            <template slot-scope="scope">
              <el-input v-model="scope.row.itemDesc" placeholder="请输入自检要求"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"  width="150">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="saveInfo(scope.row)" >保存</el-button>
              <el-button type="danger" size="mini" @click="delInfo(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>

<script>
import SysLeftTree from "./SysLeftTree";
import basic from "../../../../api/modules/basic";

export default {
  name: "SysSelfInspection",
  components: {SysLeftTree},
  data(){
    return{
      activeType: '',
      typeCode:'',
      setYear: 3,
      tableData:[]
    }
  },
  mounted() {
    if(this.activeType){
      this.getInfo()
    }
  },
  methods:{
    getInfo(){
      basic.getCheckItemList({equipType:this.activeType}).then(res =>{
        this.tableData = res.data
      })
    },
    saveInfo(row){
      if(row.selfItemId){
        // 编辑
        basic.editCheckItemInfo(row).then(res =>{
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getInfo()
        })
      }else {
        // 新增
        basic.addCheckItemInfo(row).then(res =>{
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getInfo()
        })
      }

    },
    delInfo(row,index){
      this.$confirm(`此操作将永久删除该自检项目, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(row.selfItemId){
          basic.deleteCheckItemInfo({selfItemId:row.selfItemId}).then(res =>{
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getInfo()
          })
        }else {
          this.tableData.splice(index,1)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    treeChange(item){
      this.activeType = item
      this.getInfo()
    },
    savePermiss(){
      if(!this.activeType){
        this.$message({
          message: '请先选择设备类型',
          type: 'warning'
        });
        return
      }
      this.tableData.push({
        equipTypeId: this.activeType,
        itemDesc: "",
        itemName: ""
      })
    },
    handleSelectionChange(val){
      console.log('val',val)

    }
  }
}
</script>

<style scoped lang="less">
@import "../../../../assets/css/selfcheck.less";
.permis-main{
  padding: 0;
  height: 100%;
}
.right-body{
  padding: 10px;

}


</style>