<template>
  <div class="onsite-body-table">
    <div class="onsite-tabs">
      <ul>
        <li :class="{'active':activeId == 0 }" @click="changeItem(0)">基础设置</li>
        <li :class="{'active':activeId == 1 }" @click="changeItem(1)">类型代码设置</li>
        <li :class="{'active':activeId == 2 }" @click="changeItem(2)">自检项目</li>
        <li :class="{'active':activeId == 3 }" @click="changeItem(3)">维保项目</li>
      </ul>
    </div>
    <div class="onsite-body">
      <sys-basic-set v-if="activeId == 0"></sys-basic-set>
      <sys-type-code-set v-if="activeId == 1"></sys-type-code-set>
      <sys-self-inspection v-if="activeId == 2"></sys-self-inspection>
      <sys-wei-project v-if="activeId == 3"></sys-wei-project>
    </div>
  </div>

</template>

<script>
import SysBasicSet from "../../../components/page/basic/sysConfig/SysBasicSet";
import SysTypeCodeSet from "../../../components/page/basic/sysConfig/SysTypeCodeSet";
import SysSelfInspection from "../../../components/page/basic/sysConfig/SysSelfInspection";
import SysWeiProject from "../../../components/page/basic/sysConfig/SysWeiProject";

export default {
  name: "sysConfig",
  components: {SysWeiProject, SysSelfInspection, SysTypeCodeSet, SysBasicSet},
  data(){
    return{
      activeId: 0

    }
  },
  mounted() {
  },
  methods:{
    changeItem(item){
      this.activeId = item

    }

  }
}
</script>

<style scoped lang="less">
@import "../../../assets/css/selfcheck.less";
.onsite-body-table{
  height: calc(100% - 80px);
}
.onsite-body{
  height: calc(100% - 20px);
}

</style>