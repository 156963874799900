<template>
  <div class="pageMain permis-main">
    <sys-left-tree @change="treeChange"></sys-left-tree>
    <div class="permis-right">
      <div class="right-title">
        <el-row>
          <el-col :span="12">
            <div class="title">
              <i class="el-icon-s-operation"></i>类型代码设置&nbsp;
            </div>
          </el-col>
          <el-col :span="12" class="right">
            <el-button size="small" type="primary" plain @click="savePermiss">保存</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right-body">
        <div class="setting-item">
          <div class="title">设备类型代码:</div>
          <el-input v-model="typeCode" placeholder="请输入设备类型代码"></el-input>
        </div>
        <div class="setting-item">
          <div class="title">设备超期年限::</div>
          <el-select v-model="setYear" placeholder="请选择">
            <el-option
                v-for="item in 20"
                :key="item"
                :label="`${item}年`"
                :value="item">
            </el-option>
          </el-select>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import SysLeftTree from "./SysLeftTree";
import basic from "../../../../api/modules/basic";
export default {
  name: "SysTypeCodeSet",
  components: {SysLeftTree},
  data(){
    return{
      activeType: '',
      typeCode:'',
      setYear: 3,
      activeData:{}
    }
  },
  mounted() {
    if(this.activeType){
      this.getInfo()
    }
  },
  methods:{
    getInfo(){
      basic.getEquipTypeDetail({equipTypeId:this.activeType}).then(res =>{
        this.activeData = res.data
        let {equipTypeOverage,equipTypeCode} = res.data
        this.typeCode = equipTypeCode
        this.setYear = equipTypeOverage
      })
    },
    savePermiss(){
      if(!this.activeType){
        this.$message({
          message: '请先选择设备类型',
          type: 'warning'
        });
        return
      }
      if(this.typeCode && this.setYear){
        let param = this.activeData
        param.equipTypeCode = this.typeCode
        param.equipTypeOverage = this.setYear
        basic.editEquipTypeInfo(param).then(res =>{
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getInfo()
        })
      }else {
        this.$message({
          message: '设置不能为空',
          type: 'warning'
        });
      }
    },
    treeChange(item){
      this.activeType = item
      this.getInfo()
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../../assets/css/selfcheck.less";
.permis-main{
  padding: 0;
  height: 100%;
}
.right-body{
  padding: 10px;
  .setting-item{
    margin-bottom: 20px;
    .title{
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}


</style>