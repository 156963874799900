<template>
  <div class="setting-main">
    <div class="setting-title">基础设置</div>
    <div class="setting-body">
      <div class="setting-item">
        <div class="title">设备编号规则:</div>
        <el-input
            type="textarea"
            disabled
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="codeRule">
        </el-input>
      </div>

      <div class="setting-item">
        <div class="title">自检提示内容:</div>
        <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10}"
            placeholder="请输入内容"
            v-model="promptContent">
        </el-input>
      </div>

      <div class="setting-item">
        <div class="title">自检提示时长:</div>
        <el-select v-model="setTime" placeholder="请选择">
          <el-option
              v-for="item in timeState"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="setting-item">
        <div class="title">预警日期:</div>
        <el-select v-model="setDay" placeholder="请选择">
          <el-option
              v-for="item in 28"
              :key="item"
              :label="`每月${item}号`"
              :value="item">
          </el-option>
        </el-select>
      </div>

    </div>
    <div class="setting-footer">
      <el-button type="primary" @click="saveBasic">保存</el-button>
    </div>
  </div>

</template>

<script>
import {timeState} from "../../../../config/dataStatus";
import basic from "../../../../api/modules/basic";

export default {
  name: "SysBasicSet",
  data(){
    return{
      codeRule:'{{设备来源}}{{设备类型}}{{Data}}{{Flow}}',
      promptContent:'1、要有自我保护意识，随时要对个人的安全负责。 \n2、努力学习和掌握安全知识和技能，熟练掌握本工种操作程序和安全操作规程。',
      setTime: 5,
      timeState: timeState,
      setDay: 5
    }
  },
  mounted() {
    this.getInfo()
  },
  methods:{
    getInfo(){
      basic.getSysConfigInfo({confId:1}).then(res =>{
        let {confEquip,confSelfmsg,confNoticetime,confAlarmdate} =res.data
        // this.codeRule = confEquip
        this.promptContent = confSelfmsg
        this.setTime = Number(confNoticetime)
        this.setDay = confAlarmdate
      })
    },
    saveBasic(){
      let param ={
        confEquip: this.codeRule,
        confSelfmsg: this.promptContent,
        confNoticetime: this.setTime,
        confAlarmdate: this.setDay,
        confId:1
      }
      basic.editSysConfigInfo(param).then(res =>{
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.getInfo()
      })
    },

  }
}
</script>

<style scoped lang="less">
.setting-main{
  .setting-title{
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  .setting-body{
    .setting-item{
      margin-bottom: 20px;
      .title{
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
  .setting-footer{
    text-align: right;
  }
}

</style>